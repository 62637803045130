import axios from "axios";

import { refreshTokenExpire } from "../redux-saga/redux/auth/authentication";
import { store } from "../redux-saga/store";
import config from "../utils/config";
import session from "../utils/session";

const axiosClient = axios.create({
  baseURL: config.baseUrl,
  headers: {
    "Content-Type": "application/json",
    // "domain-key": "http://localhost:4201",
    // "domain-key": "https://admin.megavega.io",
    "domain-key": "http://ec2-44-201-211-152.compute-1.amazonaws.com",
  },
});

axiosClient.interceptors.response.use(
  (res) => res,
  (err) => {
    const originalRequest = err.config;
    if (
      err.response.status === 401 &&
      originalRequest.url === "/user/refreshToken"
    ) {
      store.dispatch(refreshTokenExpire(true));
      return Promise.reject(err);
    }
    if (err.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return axiosClient.get("/user/refreshToken").then((data) => {
        if (data) {
          const { token, resToken, isMetaMaskUser } = data.data;
          const { userId } = session.getSessionCred;
          session.saveSessionCred({
            token,
            resToken,
            isMetaMaskUser,
            userId,
          });
          // Change Authorization header
          axiosClient.defaults.headers.common["access-token"] = token;
          // return originalRequest object with Axios.
          return axiosClient(originalRequest);
        }
      });
    }
    if (err?.response) {
      return Promise.reject(err.response);
    }
    return Promise.reject(err);
  }
);

axiosClient.interceptors.request.use((req) => {
  if (!navigator.onLine) {
    throw new axios.Cancel("Please connect to the internet");
  }
  const tokens = session.getSessionCred;
  if (tokens) {
    req.headers["access-token"] = tokens.token;
    req.headers["refresh-token"] = tokens.resToken;
  }
  return req;
});

export default axiosClient;
